import React from "react"
import useMediaQuery from "../hooks/useMediaQuery"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Headers/Header"
import AboutContentV1 from "../components/AboutIntermeta/ContentV/ContentV1/AboutContentV1"
import AboutContentV2 from "../components/AboutIntermeta/ContentV/ContentV2/AboutContentV2"
import AboutContentV2r from "../components/AboutIntermeta/ContentV/ContentV2/AboutContentV2r"
import AboutContentV3 from "../components/AboutIntermeta/ContentV/ContentV3/AboutContentV3"

import Background from "../images/Generalsectionbackground.png"
import BackgroundMobile from "../images/Generalsectionbackground-mobile.png"
import AboutContentV4 from "../components/AboutIntermeta/ContentV/ContentV4/AboutContentV4"

const aboutIntermetaPage = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)")
  return (
    <div>
      <Navbar />
      <Header
        title="about intermeta"
        background={Background}
        backgroundMobile={BackgroundMobile}
      />
      <article className="bg-white w-full h-auto overflow-x-hidden">
        <section className="flex flex-col mb-32 w-[80%] mx-auto items-center justify-center">
          <div className="w-[100%] flex items-center justify-center my-12">
            <h2 className="text-black text-5xl font-bold pt-5">
              Recent Updates
            </h2>
          </div>
          <div className="flex lg:flex-row flex-col items-center justify-center">
            <AboutContentV1
              IMGSrc="eee"
              Date="12.03.2022"
              Content="Lorem ipsum dolor sit amet, cons ectetur adipiscing elit"
              ButtonText="Read more"
            />
            <AboutContentV1
              IMGSrc="eee"
              Date="12.03.2022"
              Content="Lorem ipsum dolor sit amet, cons ectetur adipiscing elit"
              ButtonText="Read more"
            />
            <AboutContentV1
              IMGSrc="eee"
              Date="12.03.2022"
              Content="Lorem ipsum dolor sit amet, cons ectetur adipiscing elit"
              ButtonText="Read more"
            />
            <AboutContentV1
              IMGSrc="eee"
              Date="12.03.2022"
              Content="Lorem ipsum dolor sit amet, cons ectetur adipiscing elit"
              ButtonText="Read more"
            />
          </div>
        </section>
        <section className="flex xl:flex-row flex-col max-w-[1700px] w-[80%] mx-auto items-center justify-between mb-24">
          <AboutContentV2
            IMGSrc="eee"
            Title="Vision"
            Content="Learn more about the vision we have for asdasd  asdasd  sdasd  asdsa intermetability."
            ButtonText="More Details"
          />
          <div className="">
            {isDesktop ? (
              <AboutContentV2
                IMGSrc="eee"
                Title="Deus Engine"
                Content="Deus is the only game engine in development with intermetability at its core. 
                "
                ButtonText="More Details"
              />
            ) : (
              <AboutContentV2r
                IMGSrc="eee"
                Title="Title3"
                Content="PAR 16: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. 
                                    Lorem ipsum dolor sit amet."
                ButtonText="More Details"
              />
            )}
          </div>
        </section>
        {/* <section className="flex flex-col items-center justify-center">
          <div className="w-[100%] flex justify-center ">
            <h2 className="text-black font-bold text-5xl pb-8 lg:mt-0 mt-10">
              Title4
            </h2>
          </div>
          <div className="flex lg:flex-row flex-col items-center justify-center">
            <AboutContentV3
              Title="Title5"
              Content="PAR 18: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. 
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit"
              ButtonText="Check all documentation"
            />
            <AboutContentV3
              Title="Title6"
              Content="PAR 18: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. 
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit"
              ButtonText="Lorem ipsum"
            />
          </div>
        </section> */}
        <div>
          <div>
            <AboutContentV4 />
          </div>
        </div>
      </article>
      <Footer />
    </div>
  )
}

export default aboutIntermetaPage
