import React from "react"

interface ContentV2Props {
  IMGSrc: string
  Title: string
  Content: string
  ButtonText: string
}

const ContentV2 = ({ IMGSrc, Title, Content, ButtonText }: ContentV2Props) => {
  return (
    <div className="md:mb-32 xl:mb-0">
      <div className="flex flex-row justify-center items-center h-auto lg:mb-2 lg:my-0 lg:py-0 my-2 py-4">
        <div className="flex flex-row items-center justify-between w-full">
          <div className="lg:w-[20rem] w-[12rem] lg:h-96 h-80 bg-blue-400 aspect-[5/4] rounded-tr-[2.5rem] rounded-bl-[2.5rem]">
            {IMGSrc}
          </div>
          <div className="lg:w-[15rem] flex flex-row justify-center items-end aspect-[5/4] text-IntermetaDarkPurple">
            <div className="w-10/12 md:w-[20rem] aspect-[5/4] flex flex-col lg:justify-start justify-center relative lg:top-2 lg:right-12 right-12">
              <div className="font-semibold lg:text-3xl text-2xl">{Title}</div>
              <div className="lg:mt-2 lg:my-0 my-6 lg:text-md text-sm">
                {Content}
              </div>
              <div className="border-2 border-IntermetaDarkPurple text-IntermetaDarkPurple mt-2 aspect-[6/1] lg:w-[20rem] w-[10rem] py-2 flex items-center justify-center font-bold text-md">
                {ButtonText}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentV2
